import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import MagazineListComponent from '../components/magazineList';
import MagazineDetailComponent from '../components/magazineDetail';
import { navigate } from '@reach/router';
import axios from 'axios';
import { amplitudeLog } from '../Lib/ampletude';
import { getPlatform } from '../utils';
import { useSiteMetadata } from 'src/hooks/useSiteMetadata';
import { PageProps } from 'gatsby';
import { SEO } from 'src/components/seo';

// 빌드된 이후에 추가된 매거진들에 대해서 동적으로 데이터를 받아와 렌더링

export default function MagazineList({
  pageContext: { magazines, tags, lastestMagazineId },
  params,
  data,
  location,
}: any) {
  const [magazine, setMagazine] = useState(null as any);
  const [recommendMagazines, setRecommendMagazines] = useState(null as any);
  const [totalMagazines, setTotalMagazines] = useState([] as any);
  const [newTags, setNewTags] = useState<Array<string>>(tags);

  const { apiUrl } = useSiteMetadata();

  async function fetchNewMagazines(latestId: number) {
    const queryParams = {
      params: {
        category: 'tips',
        tag: '',
        page: 0,
        limit: 99,
        order: 'lastest',
        newerThan: latestId,
      },
    };
    const momResponse = await axios.get(`${apiUrl}/v1/boards`, queryParams);
    const petResponse = await axios.get(`${apiUrl}/pet/v1/boards`, queryParams);

    const merged = [...momResponse.data.data, ...petResponse.data.data];
    const result = [] as any[];
    const map = new Map();
    for (const item of merged) {
      if (!map.has(item.id)) {
        map.set(item.id, true);
        result.push(item);
      }
    }
    return result.sort((a, b) => b.id - a.id);
  }

  useEffect(() => {
    const magazineId = params['*'];
    if (magazineId) {
      axios
        .get(`${apiUrl}/v1/boards/detail/${magazineId}`)
        .then((response) => setMagazine(response.data.data))
        .catch((err) => {
          navigate('/magazines/');
        });

      axios
        .get(`${apiUrl}/v1/boards?category=tips&tag=&page=0&limit=4&order=random`)
        .then((response) => setRecommendMagazines(response.data.data))
        .catch((err) => {
          setRecommendMagazines([]);
        });
      amplitudeLog(`Magazine Detail View`, {
        id: magazineId,
        type: getPlatform(),
      });
    } else {
      fetchNewMagazines(lastestMagazineId)
        .then((data) => {
          setTotalMagazines([...data, ...magazines]);
          setNewTags([...new Set([...data, ...magazines].map((m) => m.tag))] as string[]);
        })
        .catch((err) => {
          navigate('/magazines/');
        });
      amplitudeLog(`Magazine List View`, { type: getPlatform() });
    }
  }, [params]);

  return (
    <Router basepath="/magazines">
      <MagazineListComponent path="/" magazines={totalMagazines} categories={newTags} />
      <MagazineDetailComponent
        path="/:magazineId/"
        magazine={magazine}
        recommendMagazines={recommendMagazines}
        location={location}
      />
    </Router>
  );
}

// 매거진 목록 화면 Head
export const Head = (props: PageProps) => {
  const { data, location, pageContext, params, serverData, ...rest } = props;
  const { descriptionMagazines, siteUrl } = useSiteMetadata();

  const title = `매거진`;
  const pageUrl = `${siteUrl}${location}`;

  return (
    <SEO title={title} description={descriptionMagazines} pageUrl={pageUrl}>
      <meta id="og_title" name="og:title" property="og:title" content={title} />
    </SEO>
  );
};
