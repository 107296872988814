// extracted by mini-css-extract-plugin
export var category = "magazineList-module--category--710e8";
export var categoryOpenBox = "magazineList-module--categoryOpenBox--d0fae";
export var categoryWrapper = "magazineList-module--categoryWrapper--fad37";
export var catwgoryBackground = "magazineList-module--catwgoryBackground--da724";
export var container = "magazineList-module--container--c0883";
export var contentContainer = "magazineList-module--contentContainer--0ace5";
export var contentWrapper = "magazineList-module--contentWrapper--d1382";
export var mobile = "magazineList-module--mobile--30390";
export var on = "magazineList-module--on--5fb90";
export var pc = "magazineList-module--pc--30dbc";
export var sort = "magazineList-module--sort--397a3";
export var topWrapper = "magazineList-module--topWrapper--44dca";