import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Mobile, PC } from './mediaQuery';
import Layout from './layout';
import MagazineCard from './magazineCard';

import * as styles from '../styles/magazineList.module.css';
import { amplitudeLog } from '../Lib/ampletude';
import { getPlatform } from '../utils';
import { StaticImage } from 'gatsby-plugin-image';
import { BoardDetailITF } from 'momguide-interface/models';
import dayjs from 'dayjs';

export default function MagazineListDefault({ path, magazines, categories }: any) {
  const [activeSort, setActiveSort] = useState('latest');
  const [activeCategory, setActiveCategory] = useState('all');
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [cookies, setCookie] = useCookies([`nonePopup`]);
  const [downloadPopup, setDownloadPopup] = useState(true);

  const onClickSort = (e: any) => {
    setActiveSort(e.target.dataset.name);
  };

  const onClickCategory = (e: any) => {
    setActiveCategory(e.target.dataset.name);
  };

  const magazineSort = (m1: any, m2: any) => {
    if (activeSort === 'latest') {
      return m2.id - m1.id;
    } else if (activeSort === 'view') {
      return m2.views - m1.views;
    }
  };
  const magazineFilter = (m: any) => activeCategory === 'all' || activeCategory === m.tag;
  const magazineFilterByDate = (magazine: BoardDetailITF) => {
    if (
      dayjs(magazine.createdAt) <= dayjs() &&
      (!magazine.expirationDate || dayjs(magazine.expirationDate) > dayjs())
    ) {
      return true;
    }
  };

  useEffect(() => {
    if (isCategoryOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, [isCategoryOpen]);

  const handleDownClick = (eventName: string) => {
    return () => {
      // amplitudeLog(`Main Click AppLink`, {
      //   type: eventName,
      // })
      amplitudeLog(`앱 설치 버튼`, {
        path: '매거진',
        type: getPlatform(),
      });
      let gotoLink = 'https://momguide.page.link/landingapp';
      window.open(gotoLink);
    };
  };
  const handleDownloadPopup = () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 1);
    setCookie('nonePopup', true, { expires });

    setDownloadPopup(!downloadPopup);
  };

  return (
    <Layout headerBold="magazine" pageTitle="매거진">
      <div className={styles.container}>
        <PC className={styles.pc}>
          <h2>안전한 가정을 위한 지침서</h2>
          <h1>맘가이드 매거진</h1>
          <div className={styles.sort}>
            <span data-name="latest" onClick={onClickSort} className={activeSort === 'latest' ? styles.on : ''}>
              최신순
            </span>
            <span data-name="view" onClick={onClickSort} className={activeSort === 'view' ? styles.on : ''}>
              조회순
            </span>
          </div>
          <div className={styles.categoryWrapper}>
            <div
              onClick={onClickCategory}
              style={{ color: 'black', fontWeight: 'bold' }}
              data-name="all"
              className={styles.category}
            >
              <p data-name="all" className={activeCategory === 'all' ? styles.on : ''}>
                전체보기
              </p>
            </div>
            {categories?.map((c, idx) => (
              <div key={idx} onClick={onClickCategory} data-name={c} className={styles.category}>
                <p data-name={c} className={activeCategory === c ? styles.on : ''}>
                  {c}
                </p>
              </div>
            ))}
          </div>

          <div className={styles.contentContainer}>
            {magazines
              .filter(magazineFilter, magazineFilterByDate)
              .sort(magazineSort)
              .map((magazine: BoardDetailITF) => {
                const magazineId = magazine.id;
                return (
                  <div key={magazineId} className={styles.contentWrapper}>
                    <MagazineCard
                      id={magazineId}
                      thumbnail={magazine.thumbnail}
                      title={magazine.title}
                      tag={magazine.tag}
                      naverPostLink={magazine.naverPostLink}
                    />
                  </div>
                );
              })}
          </div>

          {/* PC popup */}
          <div
            id="popup-pc"
            style={{
              width: '30%',
              position: 'fixed',
              bottom: '1%',
              right: '1%',
              margin: '0 auto',
              zIndex: 200,
              display: cookies.nonePopup ? 'none' : 'inline',
              imageRendering: '-webkit-optimize-contrast',
            }}
          >
            <div style={{ position: 'relative', width: '100%', zIndex: 200 }}>
              <StaticImage
                style={{ width: '100%', zIndex: 200 }}
                src="../../static/images/popup/popup_background.png"
                alt="맘가이드 설치 팝업 배경화면"
              />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '22.4%',
                  zIndex: 500,
                  width: '100%',
                }}
              >
                <img
                  style={{
                    width: '40%',
                    zIndex: 500,
                    cursor: 'pointer',
                  }}
                  src="/images/popup/download_app.png"
                  onClick={handleDownClick('m_foot')}
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '1%',
                  left: '88%',
                  zIndex: 500,
                  width: '100%',
                }}
              >
                <img
                  style={{
                    width: '10%',
                    zIndex: 500,
                    cursor: 'pointer',
                    padding: 0,
                    margin: 0,
                  }}
                  src="/images/popup/cancel_btn.png"
                  alt=""
                  onClick={handleDownloadPopup}
                />
              </div>
            </div>
          </div>
        </PC>

        <Mobile className={styles.mobile}>
          <div className={styles.topWrapper}>
            <div className={styles.categoryOpenBox} onClick={() => setIsCategoryOpen(!isCategoryOpen)}>
              <img src="/images/category_white.png" alt="카테고리 박스"></img>
              <div>카테고리</div>
            </div>
            <div className={styles.sort}>
              <span data-name="latest" onClick={onClickSort} className={activeSort === 'latest' ? styles.on : ''}>
                최신순
              </span>
              <span data-name="view" onClick={onClickSort} className={activeSort === 'view' ? styles.on : ''}>
                조회순
              </span>
            </div>
          </div>
          {isCategoryOpen ? (
            <>
              <div className={styles.categoryWrapper}>
                <div
                  onClick={onClickCategory}
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  data-name="all"
                  className={styles.category}
                >
                  <p data-name="all" className={activeCategory === 'all' ? styles.on : ''}>
                    전체보기
                  </p>
                </div>
                <div style={{ alignItems: 'stretch' }}>
                  {categories.map((c, idx) => {
                    if (c) {
                      return (
                        <div key={idx} onClick={onClickCategory} data-name={c} className={styles.category}>
                          <p
                            style={{
                              wordWrap: 'break-word',
                              display: 'inline-block',
                            }}
                            data-name={c}
                            className={activeCategory === c ? styles.on : ''}
                          >
                            {c}
                          </p>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div className={styles.catwgoryBackground}></div>
            </>
          ) : (
            ''
          )}
          <div className={styles.contentContainer}>
            {magazines
              .filter(magazineFilter)
              .sort(magazineSort)
              .map((magazine) => {
                return (
                  <div key={magazine.id} className={styles.contentWrapper}>
                    <MagazineCard
                      id={magazine.id}
                      thumbnail={magazine.thumbnail}
                      title={magazine.title}
                      tag={magazine.tag}
                      naverPostLink={magazine.naverPostLink}
                    />
                  </div>
                );
              })}
          </div>

          {/* mobile popup */}
          <div
            id="popup-mobile"
            style={{
              width: '100%',
              position: 'fixed',
              bottom: '1%',
              margin: '0 auto',
              zIndex: 200,
              display: cookies.nonePopup ? 'none' : 'inline',
              // display: 'none',
              imageRendering: '-webkit-optimize-contrast',
            }}
          >
            <div style={{ position: 'relative', width: '100%', zIndex: 200 }}>
              <StaticImage
                style={{ width: '100%', zIndex: 200 }}
                src="../../static/images/popup/popup_background.png"
                alt="맘가이드 설치 팝업 배경화면"
              />

              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '22.4%',
                  zIndex: 500,
                  width: '100%',
                }}
              >
                <img
                  style={{ width: '40%', zIndex: 500, cursor: 'pointer' }}
                  src="/images/popup/download_app.png"
                  onClick={handleDownClick('m_foot')}
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '1%',
                  left: '88%',
                  zIndex: 500,
                  width: '100%',
                }}
              >
                <img
                  style={{
                    width: '10%',
                    zIndex: 500,
                    cursor: 'pointer',
                    padding: 0,
                    margin: 0,
                  }}
                  src="/images/popup/cancel_btn.png"
                  alt=""
                  onClick={handleDownloadPopup}
                />
              </div>
            </div>
          </div>
        </Mobile>
      </div>
    </Layout>
  );
}
